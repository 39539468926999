/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/init-declarations */

import { ResourceWithId, NamedResource, SpaceResource, WorkerPoolResource, NonVcsRunbookResource, ProcessType, ProjectSummaryResource, ProjectedTeamReferenceDataItem, TeamMembership, ProjectOrSummaryResource } from "client/resources";
import Chip from "./Chip";
import {
    EnvironmentIcon,
    ExcludedEnvironmentIcon,
    ProjectIcon,
    MachineIcon,
    WorkerPoolIcon,
    DynamicWorkerPoolIcon,
    ExcludedMachineIcon,
    UnhealthyMachineIcon,
    UnavailableMachineIcon,
    ShellNameIcon,
    RunbookProcessIcon,
    DeploymentProcessIcon,
} from "../Icon/OctopusIcon"; // TODO Need to use OctopusIcon moving forward. For now, using this approach
import * as React from "react";
//TODO: Avatar should be replaced with components/Avatar however, we have some work to do to convert and consolidate our existing component.
import Avatar from "material-ui/Avatar";
//TODO: Material icons should be coming from ThirdPartyIcon, we are keeping these direct imports here for now.
import SvgTenantIcon from "@material-ui/icons/AccountCircle";
import SvgTeamIcon from "@material-ui/icons/Group";
import SvgChannelIcon from "@material-ui/icons/CallSplit";
import SvgRoleIcon from "@material-ui/icons/LocalOffer";
import SvgSpaceIcon from "@material-ui/icons/GroupWork";
import { TenantResource, CertificateResource, MachineModelHealthStatusResource, CommunicationStyleResource } from "client/resources";
import { TeamResource, TeamNameResource } from "client/resources/teamResource";
import { ProjectGroupResource } from "client/resources/projectGroupResource";
import moment from "moment";
import { UserRoleResource } from "client/resources/userRoleResource";
import NamedReferenceItem from "client/resources/namedReferenceItem";
import { DocumentTypeResource, EventCategoryResource, EventGroupResource, EventAgentResource } from "client/resources/eventResource";
import { LookupResourceChipComponent } from "../LookupResourceChip/LookupResourceChip";
import { EnvironmentResource } from "../../client/resources";
import InternalLink from "../Navigation/InternalLink/InternalLink";
import { DeploymentActionPackageResource, displayName } from "../../client/resources/deploymentActionPackageResource";
import { LocationDescriptor } from "history";
import { WorkerPoolType } from "client/resources/workerPoolsSupportedTypesResouce";
import WarningIcon from "@material-ui/icons/PriorityHigh";
import { OctopusTheme, useOctopusTheme, withTheme } from "components/Theme";
import { makeStyles } from "@material-ui/core/styles";
import { CSSProperties } from "react";
import { useKeyedItemAccess } from "components/KeyAccessProvider/KeyedItemAccessProvider";
import { DistributiveOmit } from "../../utils/distributiveMappedTypes";

const styles = require("./styles.less");

interface ExtraCommonProps {
    description?: string;
    backgroundColor?: string;
    borderColor?: string;
    markAsRemoved?: boolean;
    labelColor?: string;
    children?: any;
}

export interface CommonChipProps {
    tabIndex?: number;
    fullWidth?: boolean;
    noMargin?: boolean;
    to?: LocationDescriptor;
    descriptionPostfix?: string;
}

export interface DeletableChipProps extends CommonChipProps {
    onRequestDelete: (event: object) => void;
    deleteButtonAccessibleName: string;
}

export type CommonOrDeletableChipProps = CommonChipProps | DeletableChipProps;

function isDeletableChip(chipProps: CommonChipProps): chipProps is DeletableChipProps {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (chipProps as DeletableChipProps).onRequestDelete !== undefined;
}

type IconStyles = Pick<CSSProperties, "margin" | "fill" | "width" | "height" | "backgroundColor">;

function buildIconStyles(theme: OctopusTheme): IconStyles {
    return {
        margin: 5,
        fill: theme.chipIcon,
        width: 14,
        height: 14,
    };
}

interface AvatarStyles extends React.CSSProperties {
    // We use this object as a collection of CSS properties
    // but we expect the width and height to be numbers
    // rather than number | string.
    width: number;
    height: number;
}

function buildAvatarStyles(theme: OctopusTheme): AvatarStyles {
    return {
        width: 24,
        height: 24,
        backgroundColor: theme.avatarBackground,
    };
}

const useChipWithAvatarStyles = makeStyles({
    root: (props: ChipWithAvatarProps) => ({
        ...(props.borderColor ? { border: `solid 1px ${props.borderColor} !important`, height: "26px !important" } : {}),
    }),
});

type ChipWithAvatarProps = {
    text: string;
    icon: React.ComponentType<{ style: CSSProperties }>;
    customAvatar?: AvatarStyles;
    labelColor?: string;
    iconStyles?: CSSProperties;
    fullWidth?: boolean;
} & CommonOrDeletableChipProps &
    ExtraCommonProps;

const ChipWithAvatar: React.FC<ChipWithAvatarProps> = (props) => {
    const { text, icon: SvgIcon, customAvatar, description, labelColor, iconStyles: customIconStyles, fullWidth = false, ...rest } = props;

    const theme = useOctopusTheme();
    const avatarStyles = customAvatar || buildAvatarStyles(theme);
    const iconStyles = customIconStyles || buildIconStyles(theme);
    const classes = useChipWithAvatarStyles(props);

    const theChip = (
        <Chip
            backgroundColor={theme.chipBackground}
            fullWidth={fullWidth}
            labelColor={labelColor || theme.chipText}
            description={description || text}
            avatar={<Avatar style={avatarStyles} size={avatarStyles.width} icon={<SvgIcon style={iconStyles} />} />}
            onRequestDelete={isDeletableChip(props) ? props.onRequestDelete : undefined}
            deleteButtonAccessibleName={isDeletableChip(props) ? props.deleteButtonAccessibleName : undefined}
            className={classes.root}
            {...rest}
        >
            {text}
        </Chip>
    );

    if (props.to) {
        return (
            <InternalLink to={props.to} className={styles.clickableChip}>
                {theChip}
            </InternalLink>
        );
    }
    return theChip;
};

export enum ChipIcon {
    Project,
    Machine,
    ExcludedMachine,
    Environment,
    ExcludedEnvironment,
    Role,
    ShellName,
    Tenant,
    Channel,
    Team,
    Step,
    StepAction,
    ProjectGroup,
    CommunicationStyle,
    EventCategory,
    EventGroup,
    EventAgent,
    MachineModelHealthStatus,
    DocumentType,
    LibraryVariableSet,
    Space,
    Runbook,
    DeploymentProcess,
    Warning,
}

// NOTE:
// This is used to show the user only what they can see, it may be filtered away by permissions
export function matchesToChips<T extends ResourceWithId>(set: T[], selected: string[], createChip: (x: T) => JSX.Element): JSX.Element[] {
    return set.filter((item) => selected && selected.indexOf(item.Id) !== -1).map((item) => createChip(item));
}

export function channelChipList(set: NamedResource[], selectedIds: string[]) {
    return matchesToChips(set, selectedIds, (x) => <ChannelChip channelName={x.Name} key={x.Id} />);
}

export function environmentChipList(set: NamedResource[], selectedIds: string[]) {
    return matchesToChips(set, selectedIds, (x) => <EnvironmentChip environmentName={x.Name} key={x.Id} />);
}

export function workerPoolChipList(set: WorkerPoolResource[], selectedIds: string[]) {
    return matchesToChips(set, selectedIds, (x) => <WorkerPoolChip workerPoolName={x.Name} key={x.Id} workerPoolType={x.WorkerPoolType} />);
}

// NOTE:
// We have some inconsistency in the application where on summaries we filter away what the user cannot see due to permissions
// eg environments, but if they expand the multiselect we show them missing chips
// we should aim to move to this approach in `environmentChipListIncludingMissing` and SHOW them Ids and Names
// it will require a new API (as not to break other things)
// the objective is to  drive people to Spaces for isolation instead of using the permissions system awkwardly
export function environmentChipListIncludingMissing(set: EnvironmentResource[], selectedIds: string[]) {
    const EnvironmentLookupChipInternal = LookupResourceChipComponent<EnvironmentResource>();

    return selectedIds.map((id) => <EnvironmentLookupChipInternal lookupCollection={set} key={id} lookupId={id} type={ChipIcon.Environment} chipRender={(item) => <EnvironmentChip environmentName={item.Name} />} />);
}

export function tenantChipList(set: TenantResource[], selectedIds: string[]) {
    return matchesToChips(set, selectedIds, (x) => <TenantChip tenantName={x.Name} key={x.Id} />);
}

// Same applies environmentChipListIncludingMissing
// future goal to replace all the variants with this approach
export function tenantChipListIncludingMissing(set: TenantResource[], selectedIds: string[]) {
    const TenantLookupChipInternal = LookupResourceChipComponent<TenantResource>();

    return selectedIds.map((id) => <TenantLookupChipInternal lookupCollection={set} key={id} lookupId={id} type={ChipIcon.Tenant} chipRender={(item) => <TenantChip tenantName={item.Name} />} />);
}

export function projectGroupChipList(set: ProjectGroupResource[], selectedIds: string[]) {
    return matchesToChips(set, selectedIds, (x) => <ProjectGroupChip projectGroup={x} key={x.Id} />);
}

export function projectChipList(set: ProjectSummaryResource[], selectedIds: string[]) {
    return matchesToChips(set, selectedIds, (x) => <ProjectChip project={x} key={x.Id} />);
}

export function documentChipList(set: DocumentTypeResource[], selectedIds: string[]) {
    return matchesToChips(set, selectedIds, (x) => <DocumentTypeChip documentType={x} key={x.Id} />);
}

export function eventCategoryList(set: EventCategoryResource[], selectedIds: string[]) {
    return matchesToChips(set, selectedIds, (x) => <EventCategoryChip eventCategory={x} key={x.Id} />);
}

export function eventGroupList(set: EventGroupResource[], selectedIds: string[]) {
    return matchesToChips(set, selectedIds, (x) => <EventGroupChip eventGroup={x} key={x.Id} />);
}

export function eventAgentList(set: EventAgentResource[], selectedIds: string[]) {
    return matchesToChips(set, selectedIds, (x) => <EventAgentChip eventAgent={x} key={x.Id} />);
}

export function spaceChipList(set: SpaceResource[], selectedIds: string[]) {
    return matchesToChips(set, selectedIds, (x) => <SpaceChip space={x} key={x.Id} />);
}

type MissingOrGhostChipProps = { lookupId: string; type?: ChipIcon } & CommonOrDeletableChipProps;

const MissingChip: React.FC<MissingOrGhostChipProps> = (props) => {
    const theme = useOctopusTheme();
    const { lookupId, type, ...rest } = props;
    const text = "Missing Resource";

    // There are cases where this isn't right, but will just have 1 message to simplify
    // e.g. Variable Snapshots will show this for deleted environments, it's not an issue in that case, but good to show this.
    const description =
        `The ${ChipIcon[type!]} document '${lookupId}' referenced by this record is no longer available or ` +
        "you do not have permissions to see this resource. Please check with you Octopus Administrator regarding your " +
        "permissions. If you believe the resource is missing (and this is not permissions-related), please let Octopus " +
        "support know so that we can prevent this from happening in the future.";

    const customIconStyles: IconStyles = {
        ...buildIconStyles(theme),
        fill: theme.whiteConstant,
        backgroundColor: theme.dangerConstant,
    };

    const customAvatarStyles = {
        ...buildAvatarStyles(theme),
        backgroundColor: theme.dangerConstant,
    };

    const renderChip = (svg: React.ComponentType<{ style: IconStyles }>) => (
        <ChipWithAvatar text={text} icon={svg} backgroundColor={theme.chipBackground} customAvatar={customAvatarStyles} description={description} labelColor={theme.chipText} iconStyles={customIconStyles} {...rest} />
    );

    switch (type) {
        case ChipIcon.Project:
            return renderChip(ProjectIcon);
        case ChipIcon.Machine:
            return renderChip(MachineIcon);
        case ChipIcon.ExcludedMachine:
            return renderChip(ExcludedMachineIcon);
        case ChipIcon.Environment:
            return renderChip(EnvironmentIcon);
        case ChipIcon.ExcludedEnvironment:
            return renderChip(ExcludedEnvironmentIcon);
        case ChipIcon.Role:
            return renderChip(SvgRoleIcon);
        case ChipIcon.ShellName:
            return renderChip(ShellNameIcon);
        case ChipIcon.Tenant:
            return renderChip(SvgTenantIcon);
        case ChipIcon.Channel:
            return renderChip(SvgChannelIcon);
        case ChipIcon.Team:
            return renderChip(SvgTeamIcon);
        case ChipIcon.Space:
            return renderChip(SvgSpaceIcon);
        case ChipIcon.Runbook:
            return renderChip(RunbookProcessIcon);
        case ChipIcon.DeploymentProcess:
            return renderChip(DeploymentProcessIcon);
        case ChipIcon.Step:
        case ChipIcon.StepAction:
        case ChipIcon.ProjectGroup:
        case ChipIcon.CommunicationStyle:
        case ChipIcon.EventCategory:
        case ChipIcon.EventGroup:
        case ChipIcon.EventAgent:
        case ChipIcon.MachineModelHealthStatus:
        case ChipIcon.DocumentType:
            return (
                <Chip backgroundColor={theme.dangerBackground} labelColor={theme.dangerText} description={description} {...rest}>
                    {text}
                </Chip>
            );
        case ChipIcon.Warning:
        default:
            return renderChip(WarningIcon);
    }
};

export const GhostChip: React.FC<MissingOrGhostChipProps> = (props) => {
    const theme = useOctopusTheme();
    const { lookupId, type, ...rest } = props;
    const description = `The reference '${lookupId}' could not be found. Please check the specified name and permissions for the resource.`;

    const customIconStyles: CSSProperties = {
        ...buildIconStyles(theme),
        fill: theme.alert,
    };

    const customAvatarStyles = {
        ...buildAvatarStyles(theme),
        backgroundColor: theme.transparent,
        marginRight: "-10px",
    };

    const renderChip = (svg: React.ComponentType<{ style: IconStyles }>) => (
        <ChipWithAvatar
            text={props.lookupId}
            icon={svg}
            backgroundColor={theme.transparent}
            borderColor={theme.divider}
            customAvatar={customAvatarStyles}
            description={description}
            labelColor={theme.chipText}
            iconStyles={customIconStyles}
            {...rest}
        />
    );

    switch (type) {
        case ChipIcon.Project:
            return renderChip(ProjectIcon);
        case ChipIcon.Machine:
            return renderChip(MachineIcon);
        case ChipIcon.ExcludedMachine:
            return renderChip(ExcludedMachineIcon);
        case ChipIcon.Environment:
            return renderChip(EnvironmentIcon);
        case ChipIcon.ExcludedEnvironment:
            return renderChip(ExcludedEnvironmentIcon);
        case ChipIcon.Role:
            return renderChip(SvgRoleIcon);
        case ChipIcon.ShellName:
            return renderChip(ShellNameIcon);
        case ChipIcon.Tenant:
            return renderChip(SvgTenantIcon);
        case ChipIcon.Channel:
            return renderChip(SvgChannelIcon);
        case ChipIcon.Team:
            return renderChip(SvgTeamIcon);
        case ChipIcon.Space:
            return renderChip(SvgSpaceIcon);
        case ChipIcon.Runbook:
            return renderChip(RunbookProcessIcon);
        case ChipIcon.DeploymentProcess:
            return renderChip(DeploymentProcessIcon);
        case ChipIcon.Step:
        case ChipIcon.StepAction:
        case ChipIcon.ProjectGroup:
        case ChipIcon.CommunicationStyle:
        case ChipIcon.EventCategory:
        case ChipIcon.EventGroup:
        case ChipIcon.EventAgent:
        case ChipIcon.MachineModelHealthStatus:
        case ChipIcon.DocumentType:
            return (
                <Chip backgroundColor={theme.alertBackground} labelColor={theme.alertText} description={description} {...rest}>
                    {props.lookupId}
                </Chip>
            );
        case ChipIcon.Warning:
        default:
            return renderChip(WarningIcon);
    }
};

const hasDeleteChipPropCallback = (props: CommonOrDeletableChipProps): props is CommonChipProps & Pick<DeletableChipProps, "onRequestDelete"> => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const converted = props as DeletableChipProps;
    return typeof converted.onRequestDelete === "function";
};

type ContextualMissingChipProps = DistributiveOmit<MissingOrGhostChipProps, "lookupId"> & {
    lookupKey: string;
};

export const ContextualMissingChip: React.FC<ContextualMissingChipProps> = ({ lookupKey, ...rest }) => {
    const keyedBy = useKeyedItemAccess();
    const ChipComponent = keyedBy === "Name" ? GhostChip : MissingChip;
    const propsToSpread = hasDeleteChipPropCallback(rest) ? { ...rest, deleteButtonAccessibleName: keyedBy === "Name" ? "Remove Unknown Resource" : "Delete Missing Resource" } : rest;

    return <ChipComponent lookupId={lookupKey} {...propsToSpread} />;
};

export default ContextualMissingChip;

type ProcessChipProps = { processType: ProcessType | undefined; name: string } & CommonOrDeletableChipProps;

const ProcessChip: React.FC<ProcessChipProps> = ({ processType, name, ...rest }) => {
    switch (processType) {
        case ProcessType.Deployment:
            return <ChipWithAvatar text={name} icon={DeploymentProcessIcon} description={name} {...rest} />;
        case ProcessType.Runbook:
            return <ChipWithAvatar text={name} icon={RunbookProcessIcon} description={`Runbook: ${name}`} {...rest} />;
    }

    return null;
};

const ProjectChip: React.FC<{ project: ProjectOrSummaryResource } & CommonOrDeletableChipProps> = (props) => {
    const { project, ...rest } = props;
    return <ChipWithAvatar text={project.Name} icon={ProjectIcon} description={`Project: ${project.Name}`} {...rest} />;
};

const ProjectGroupChip: React.FC<{ projectGroup: ProjectGroupResource } & CommonOrDeletableChipProps> = (props) => {
    const { projectGroup, ...rest } = props;
    const description = "Project group: " + projectGroup.Name;
    return (
        <Chip description={description} {...rest}>
            {projectGroup.Name}
        </Chip>
    );
};

const RunbookChip: React.FC<{ runbook: NonVcsRunbookResource } & CommonOrDeletableChipProps> = (props) => {
    const { runbook, ...rest } = props;
    return <ChipWithAvatar text={runbook.Name} icon={RunbookProcessIcon} description={`Runbook: ${runbook.Name}`} {...rest} />;
};

const MachineModelHealthStatusChip: React.FC<
    {
        healthStatus: MachineModelHealthStatusResource;
    } & CommonOrDeletableChipProps
> = (props) => {
    const { healthStatus, ...rest } = props;
    const description = "Machine health: " + healthStatus.Name;
    return (
        <Chip description={description} {...rest}>
            {healthStatus.Name}
        </Chip>
    );
};

const EndpointCommunicationStyleChip: React.FC<
    {
        commStyle: CommunicationStyleResource;
    } & CommonOrDeletableChipProps
> = (props) => {
    const { commStyle, ...rest } = props;
    const description = "Communications style: " + commStyle.Name;
    return (
        <Chip description={description} {...rest}>
            {commStyle.Name}
        </Chip>
    );
};

const DeploymentActionChip: React.FC<
    {
        stepName: string;
    } & CommonOrDeletableChipProps
> = (props) => {
    const { stepName, ...rest } = props;
    const description = "Step: " + stepName;
    return (
        <Chip description={description} {...rest}>
            {stepName}
        </Chip>
    );
};

const EnvironmentChip: React.FC<
    {
        environmentName: string;
        isExcluded?: boolean;
    } & CommonOrDeletableChipProps
> = (props) => {
    const { environmentName, isExcluded, ...rest } = props;
    return <ChipWithAvatar text={environmentName} icon={isExcluded ? ExcludedEnvironmentIcon : EnvironmentIcon} description={`Environment: ${environmentName}`} markAsRemoved={isExcluded} {...rest} />;
};

const WorkerPoolChip: React.FC<
    {
        workerPoolName: string;
        isExcluded?: boolean;
        workerPoolType: WorkerPoolType;
    } & CommonOrDeletableChipProps
> = (props) => {
    const { workerPoolName, isExcluded, workerPoolType, ...rest } = props;
    const icon = workerPoolType === WorkerPoolType.Static ? WorkerPoolIcon : DynamicWorkerPoolIcon;
    return (
        <ChipWithAvatar
            text={workerPoolName}
            icon={isExcluded ? ExcludedEnvironmentIcon : icon}
            description={workerPoolType === WorkerPoolType.Static ? `Worker pool: ${workerPoolName}` : `Dynamic worker pool: ${workerPoolName}`}
            markAsRemoved={isExcluded}
            {...rest}
        />
    );
};

const MachineChip: React.FC<
    {
        machineName: string;
        isExcluded?: boolean;
        isDisable?: boolean;
        isUnhealthy?: boolean;
    } & CommonOrDeletableChipProps
> = (props) => {
    const { machineName, isExcluded, isDisable, isUnhealthy, ...rest } = props;
    const theme = useOctopusTheme();

    let icon: React.ComponentType<{ style: IconStyles }>;

    if (isExcluded || isDisable) {
        icon = UnavailableMachineIcon;
    } else if (isUnhealthy) {
        icon = UnhealthyMachineIcon;
    } else {
        icon = MachineIcon;
    }

    return (
        <ChipWithAvatar
            text={machineName}
            icon={icon}
            description={`Machine: ${machineName}`}
            markAsRemoved={isExcluded}
            backgroundColor={isDisable || isUnhealthy ? theme.dangerBackground : theme.chipBackground}
            labelColor={isDisable || isUnhealthy ? theme.dangerText : theme.chipText}
            customAvatar={isDisable || isUnhealthy ? { ...buildAvatarStyles(theme), backgroundColor: theme.dangerConstant } : undefined}
        />
    );
};

const TenantChip: React.FC<{ tenantName: string } & CommonOrDeletableChipProps> = (props) => {
    const { tenantName, ...rest } = props;
    return <ChipWithAvatar text={tenantName} icon={SvgTenantIcon} description={`Tenant: ${tenantName}`} {...rest} />;
};

const TeamChip: React.FC<{ team: TeamResource | TeamNameResource | ProjectedTeamReferenceDataItem | TeamMembership } & CommonOrDeletableChipProps> = (props) => {
    const { team, ...rest } = props;

    const teamName = "TeamName" in team ? team.TeamName : team.Name;
    const desc = !rest.descriptionPostfix ? "Team: " + teamName : "Team: " + teamName + rest.descriptionPostfix;
    return <ChipWithAvatar text={teamName} icon={SvgTeamIcon} description={desc} {...rest} />;
};

const ChannelChip: React.FC<{ channelName: string } & CommonOrDeletableChipProps> = (props) => {
    const { channelName, ...rest } = props;
    return <ChipWithAvatar text={channelName} icon={SvgChannelIcon} description={`Channel: ${channelName}`} {...rest} />;
};

const RoleChip: React.FC<{ role: string } & CommonOrDeletableChipProps> = (props) => {
    const { role, ...rest } = props;
    return <ChipWithAvatar text={role} icon={SvgRoleIcon} description={`Role: ${role}`} {...rest} />;
};

const ShellNameChip: React.FC<{ shellName: string } & CommonOrDeletableChipProps> = (props) => {
    const { shellName, ...rest } = props;
    return <ChipWithAvatar text={shellName} icon={ShellNameIcon} description={`Shell: ${shellName}`} {...rest} />;
};

const CertificateExpiryChip: React.FC<{ certificate: CertificateResource } & CommonOrDeletableChipProps> = (props) => {
    const theme = useOctopusTheme();
    const { certificate, ...rest } = props;
    const now = moment();
    const certificateExpiry = moment(certificate.NotAfter);
    const prefix = certificateExpiry.isAfter(now) ? "Expires " : "Expired ";
    const expiry = prefix + certificateExpiry.fromNow();
    let color = theme.success;
    if (certificateExpiry.isBefore(now)) {
        color = theme.danger;
    } else if (certificateExpiry.isBefore(now.add(20, "days"))) {
        color = theme.alert;
    }
    const description = "Certificate expiry: " + expiry;
    return (
        <Chip description={description} backgroundColor={"#00000000"} bordercolor={color} labelColor={color} {...rest}>
            {expiry}
        </Chip>
    );
};

const StepChip: React.FC<{ stepName: string; suffix?: React.ReactNode } & CommonOrDeletableChipProps> = (props) => {
    const { stepName, suffix, ...rest } = props;
    const description = "Step: " + stepName;
    return (
        <Chip description={description} {...rest}>
            {stepName}
            {suffix}
        </Chip>
    );
};

const DeploymentActionPackageChip: React.FC<{ actionPackage: DeploymentActionPackageResource } & CommonOrDeletableChipProps> = (props) => {
    const { actionPackage, ...rest } = props;
    const description = !actionPackage.PackageReference ? `Step ${actionPackage.DeploymentAction}` : `Package ${actionPackage.PackageReference} from step ${actionPackage.DeploymentAction}`;
    return (
        <Chip description={description} {...rest}>
            {displayName(actionPackage)}
        </Chip>
    );
};

const UserRoleChip: React.FC<{ userRole: UserRoleResource } & CommonOrDeletableChipProps> = (props) => {
    const { userRole, ...rest } = props;
    return <Chip {...rest}>{userRole.Name}</Chip>;
};

const ExternalSecurityGroupChip: React.FC<{ group: NamedReferenceItem } & CommonOrDeletableChipProps> = (props) => {
    const { group, ...rest } = props;
    const fullName = group.DisplayIdAndName ? `${group.DisplayName} (${group.Id})` : group.DisplayName;
    return (
        <Chip description={`Indirectly assigned via ${fullName}`} {...rest}>
            {group.DisplayName}
        </Chip>
    );
};

const FilterTextChip: React.FC<{ filterText: string } & CommonOrDeletableChipProps> = (props) => {
    const { filterText, ...rest } = props;
    return <Chip {...rest}>{filterText}</Chip>;
};

const EventCategoryChip: React.FC<{ eventCategory: EventCategoryResource } & CommonOrDeletableChipProps> = (props) => {
    const { eventCategory, ...rest } = props;
    const description = "Event category: " + eventCategory.Name;
    return (
        <Chip description={description} {...rest}>
            {eventCategory.Name}
        </Chip>
    );
};

const EventCategoryPreviewChip: React.FC<{ eventCategory: EventCategoryResource } & CommonOrDeletableChipProps> = (props) => {
    const { eventCategory, ...rest } = props;
    const description = "Event category: " + eventCategory.Name;
    return (
        <Chip description={description} {...rest}>
            {eventCategory.Name}
        </Chip>
    );
};

const EventAgentChip: React.FC<{ eventAgent: EventAgentResource } & CommonOrDeletableChipProps> = (props) => {
    const { eventAgent, ...rest } = props;
    const description = "Event agent: " + eventAgent.Name;
    return (
        <Chip description={description} {...rest}>
            {eventAgent.Name}
        </Chip>
    );
};

const DocumentTypeChip: React.FC<{ documentType: DocumentTypeResource } & CommonOrDeletableChipProps> = (props) => {
    const { documentType, ...rest } = props;
    const description = "Document type: " + documentType.Name;
    return (
        <Chip description={description} {...rest}>
            {documentType.Name}
        </Chip>
    );
};

const EventGroupChip: React.FC<{ eventGroup: EventGroupResource } & CommonOrDeletableChipProps> = (props) => {
    const { eventGroup, ...rest } = props;
    const description = "Event group: " + eventGroup.Name;
    return (
        <Chip description={description} {...rest}>
            {eventGroup.Name}
        </Chip>
    );
};

const DisabledChip: React.FC<CommonOrDeletableChipProps> = (props) =>
    withTheme((theme) => (
        <Chip {...props} bordercolor={theme.disabledButtonBorder} labelColor={theme.disabledButtonText} backgroundColor={theme.transparent}>
            Disabled
        </Chip>
    ));

const DefaultOptionChip: React.FC<CommonOrDeletableChipProps> = (props) => {
    return <Chip {...props}>Default</Chip>;
};

const LookupTenantChip: React.FC<{ lookupTenants: TenantResource[]; id: string } & CommonOrDeletableChipProps> = (props) => {
    const TenantLookupChipInternal = LookupResourceChipComponent<TenantResource>();
    return <TenantLookupChipInternal lookupCollection={props.lookupTenants} key={props.id} lookupId={props.id} type={ChipIcon.Tenant} chipRender={(item) => <TenantChip tenantName={item.Name} to={props.to} />} />;
};

const SpaceChip: React.FC<{ space: SpaceResource; description?: string } & CommonOrDeletableChipProps> = (props) => {
    const { space, description, ...rest } = props;
    return <ChipWithAvatar text={space.Name} icon={SvgSpaceIcon} description={description || space.Description} {...rest} />;
};

const DefaultSpaceChip: React.FC<CommonOrDeletableChipProps> = (props) => {
    return (
        <Chip {...props} description="Default Space">
            Default
        </Chip>
    );
};

const EarlyAccessChip: React.FC<CommonOrDeletableChipProps> = (props) =>
    withTheme((theme: OctopusTheme) => (
        <Chip backgroundColor={theme.alertHighlight} bordercolor={theme.alert} labelColor={theme.alert} {...props} description={"Early access features are still in development and we encourage you to experiment with this"}>
            EAP
        </Chip>
    ));

const RunbookSnapshotPublishedChip: React.FC<CommonOrDeletableChipProps> = (props) =>
    withTheme((theme) => (
        <Chip backgroundColor={theme.success} labelColor={theme.paper0} {...props} description={"This published snapshot is ready to run."}>
            Published
        </Chip>
    ));

const NewFeatureChip: React.FC<CommonOrDeletableChipProps> = (props) =>
    withTheme((theme) => (
        <Chip backgroundColor={theme.featureBackground} bordercolor={theme.cyanConstant} labelColor={theme.featureText} {...props} description="New Feature">
            NEW
        </Chip>
    ));

const WarningChip: React.FC<{ description: string; title: string } & CommonChipProps> = (props) =>
    withTheme((theme) => (
        <Chip backgroundColor={theme.alertBackground} bordercolor={theme.alertBorder} labelColor={theme.alertHeaderText} {...props} description={props.description}>
            {props.title}
        </Chip>
    ));

const DangerChip: React.FC<{ description: string; title: string } & CommonChipProps> = (props) =>
    withTheme((theme) => (
        <Chip backgroundColor={theme.dangerBackground} bordercolor={theme.dangerBorder} labelColor={theme.dangerHeaderText} {...props} description={props.description}>
            {props.title}
        </Chip>
    ));

export {
    MissingChip,
    ProjectChip,
    RunbookChip,
    ProjectGroupChip,
    EnvironmentChip,
    WorkerPoolChip,
    RoleChip,
    ShellNameChip,
    TenantChip,
    TeamChip,
    ChannelChip,
    MachineChip,
    CertificateExpiryChip,
    MachineModelHealthStatusChip,
    EndpointCommunicationStyleChip,
    StepChip,
    DeploymentActionPackageChip,
    UserRoleChip,
    ExternalSecurityGroupChip,
    FilterTextChip,
    EventCategoryChip,
    EventCategoryPreviewChip,
    EventAgentChip,
    DocumentTypeChip,
    EventGroupChip,
    DisabledChip,
    DefaultOptionChip,
    LookupTenantChip,
    SpaceChip,
    DefaultSpaceChip,
    EarlyAccessChip,
    NewFeatureChip,
    DeploymentActionChip,
    RunbookSnapshotPublishedChip,
    ProcessChip,
    WarningChip,
    DangerChip,
};
